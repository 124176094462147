<template>
  <div v-html="text"></div>
</template>

<script>
import backend from '@/backend'

export default {
  name: 'BrandExplanation',
  props: {
    reference: Number
  },
  data () {
    return {
      text: ''
    }
  },
  mounted () {
    if (this.reference) {
      backend
        .get(`${process.env.VUE_APP_URL}/page-block/${this.reference}`)
        .then(response => {
          if (response.data.helper.length > 0) {
            // For now we support only 1 helper. So we return the first one.
            this.text = response.data.helper[0].description
          }
        })
    }
  }
}
</script>
