<template>
  <div v-if="$store.state.is_brand_manager">
    <portal to="pageHeaderRight">
      <contact-dropdown/>
      <router-button
        routeName="BrandDescribe"
        :routeParam="$route.params.pathName"
        :text="$t('brand.watch')"
        icon="icon-tips"
        class="ab-button--white ab-button--description"
        target="_blank"
      />
      <submit-button :isSaving="isSaving"/>
      <cancel-button @cancel="cancelEdit($route.path)"/>
      <delete-button
        @delete="removePage($route.params.pathName)"
        v-if="$store.state.is_brand_manager"
      ></delete-button>
    </portal>
    <div class="page-edit__container">
      <div class="page-edit__content" id="pageEdit__container">
        <form @submit.prevent="formSubmit" @keydown.enter.prevent.self>
          <input type="submit" id="submitForm" hidden=""/>
          <div class="grid-x grid-margin-x">
            <div
              class="cell page__inner-content"
              :class="[
                $route.params.pathName == 'dashboard' ? 'medium-12' : 'auto'
              ]"
            >
              <edit-content-repeater
                v-bind:contentItems.sync="brandData.value"
                :dropzoneReady="dropzoneReady"
                :dragOptions="dragOptions"
                :isMainContent="true"
                @remove="removeValueBlock"
                @add-item="openBrandData"
              ></edit-content-repeater>
              <add-brand-component
                v-if="brandData.value.length == 0"
                @add-item="openBrandData(0, true)"
                cssClass="add-border"
              />
            </div>
            <div
              class="cell medium-3 page-sidebar"
              :class="sidebarWidth ? 'has-width' : ''"
              ref="sidebar"
              v-if="$route.params.pathName != 'dashboard'"
            >
              <aside>
                <edit-content-repeater
                  v-bind:contentItems.sync="brandData.sidebar"
                  :dropzoneReady="dropzoneReady"
                  :dragOptions="dragOptions"
                  :isMainContent="false"
                  @remove="removeSidebarBlock"
                  @add-item="openBrandData"
                ></edit-content-repeater>
                <add-brand-component
                  v-if="brandData.sidebar.length == 0"
                  @add-item="openBrandData(0, false)"
                />
              </aside>
            </div>
          </div>
        </form>
      </div>
      <div
        class="page-edit__explanation"
        :style="{ flex: sidebarWidth ? '0 0 ' + sidebarSize : '0 0 0' }"
      >
        <div class="page-edit__explanation__header">
          <span class="button__close" @click="closeExplanation"
          >{{ $t('app.close') }} <i class="icon-close"></i
          ></span>
        </div>
        <div
          class="page-edit__explanation-inner"
          :style="{ 'min-width': sidebarSize }"
        >

          <BrandExplanation
            :reference="reference"
            :key="reference"
          ></BrandExplanation>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <h3>Unauthorized</h3>
  </div>
</template>

<script>
import backend from '@/backend'
import { dirtyRoute } from '@/views/common/mixins/routes/dirtyRoute'
import addBrandData from '../components/edit/elements/modal/addBrandData'
import EditContentRepeater from '@/views/brand/components/EditContentRepeater'
import BrandExplanation from '@/views/brand/components/BrandExplanation'

export default {
  name: 'BrandDetailEdit',
  mixins: [dirtyRoute],
  data () {
    return {
      brandData: {
        value: [],
        sidebar: []
      },
      title: '',
      slug: '',
      dropzoneReady: false,
      drag: false,
      exists: false,
      isSaving: false,
      sidebarWidth: false,
      sidebarSize: '',
      reference: null
    }
  },
  created () {
    backend
      .get(`${process.env.VUE_APP_URL}/pages/${this.$route.params.pathName}`)
      .then(response => {
        this.brandData.value = response.data.value
        this.brandData.sidebar = response.data.sidebar
        this.title = response.data.name
        this.slug = response.data.slug
        this.dropzoneReady = true
        for (let i = 0; i < this.brandData.value.length; i++) {
          this.brandData.value[i].uid = i
        }
        for (let i = 0; i < this.brandData.sidebar.length; i++) {
          this.brandData.sidebar[i].uid = i
        }
        this.exists = true
        this.$nextTick(() => {
          this.setDirty(false)
        })
      })
    this.$setupKeypress('s', this.formSubmit)
  },
  mounted () {
    if (this.$route.params.template) {
      // Get details from backend about selected template
      backend
        .get(
          `${process.env.VUE_APP_URL}/page-template/${this.$route.params.template}`
        )
        .then(response => {
          const blocks = response.data.blocks
          for (let i = 0; i < blocks.length; i++) {
            this.brandData.value.push({
              _group: blocks[i].component,
              _title: blocks[i].title,
              uid: i,
              items: [],
              colors: [],
              page_block_id: blocks[i].id,
              has_helper: blocks[i].helper.length > 0,
              reference: blocks[i].reference
            })
          }
        })
    }
  },
  computed: {
    dragOptions () {
      return {
        scrollSensitivity: 200,
        forceFallback: true
      }
    }
  },
  methods: {
    openExplanation (value) {
      if (!this.sidebarWidth) {
        this.reference = value
        this.sidebarSize = this.$refs.sidebar.clientWidth + 226 + 40 + 'px'
        this.sidebarWidth = !this.sidebarWidth
      } else {
        this.reference = value
      }
    },
    closeExplanation () {
      this.sidebarWidth = false
    },
    removePage (path) {
      this.$modal.show('dialog', {
        title: '',
        text: this.$t('app.remove_page'),
        buttons: [
          {
            title: this.$t('app.cancel'),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: this.$t('app.yes'),
            default: true,
            handler: () => {
              backend
                .delete(`${process.env.VUE_APP_URL}/pages/${path}`)
                .then(() => {
                  this.$emit('updateBrandPages')
                  this.$notify({
                    group: 'custom-template',
                    title: false,
                    position: 'bottom left',
                    text: this.$t('app.page_removed')
                  })
                  this.$modal.hide('dialog')
                  this.$router.push({ name: 'BrandList' })
                })
            }
          }
        ]
      })
    },
    returnListItem () {
      if (this.$route.params.pathName === 'dashboard') {
        this.$router.push({ name: 'Dashboard' })
      } else {
        this.$router.push({
          name: 'BrandDetail',
          params: { pathName: this.$route.params.pathName }
        })
      }
    },
    cancelEdit (path) {
      if (this.exists) {
        const url = path.replace('/edit', '')
        this.$router.push(url)
      } else {
        this.$router.push({ name: 'BrandList' })
      }
    },
    formSubmit () {
      this.isSaving = true
      this.dirty = false
      const vm = this
      const keys = ['sidebar', 'value']
      keys.forEach(key => {
        const data = vm.brandData[key]
        data.forEach(dataItem => {
          delete dataItem.component
          delete dataItem.id
        })
      })

      backend
        .post(
          `${process.env.VUE_APP_URL}/pages/merk/${this.$route.params.pathName}`,
          {
            items: this.brandData
          }
        )
        .then(() => {
          this.$emit('updateBrandPages')
          if (this.$route.params.pathName === 'dashboard') {
            this.$router.push('/')
          } else {
            const url = this.$route.path.replace('/edit', '')
            this.$router.push(url)
          }
        })
        .catch(() => {
          this.isSaving = false
        })
    },
    openBrandData (index, type) {
      this.$modal.show(
        addBrandData,
        {
          id: index,
          brandData: this.brandData,
          type: type
        },
        {
          draggable: false,
          scrollable: true,
          height: 'auto',
          width: '860px'
        }
      )
    },
    hide (id) {
      this.$modal.hide('modal' + id)
    },
    removeValueBlock (index) {
      this.brandData.value.splice(index, 1)
    },
    removeSidebarBlock (index) {
      this.brandData.sidebar.splice(index, 1)
    }
  },
  watch: {
    brandData: {
      handler (newVal, oldVal) {
        this.setDirty(true)
      },
      deep: true
    },
    sidebarWidth: {
      handler (newVal, oldVal) {
        if (this.sidebarWidth) {
          this.$store.state.menu_is_open = false
        } else {
          this.$store.state.menu_is_open = true
        }
      },
      deep: true
    }
  },
  components: {
    EditContentRepeater,
    BrandExplanation
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/init/_init";

.page-edit {
  &__container {
    display: flex;
  }

  &__content {
    width: 100%;
    padding: $page-content-padding;
  }

  &__explanation {
    transition: all 150ms ease-in-out;
    position: sticky;
    top: 0;
    flex: 0 0 0;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh);
    background: var(--menu-secondary-color);
    color: $white !important;
    border-radius: $global-radius 0 0 0;
    box-shadow: rgba($gray, 0.3) -2px 2px 5px;
    margin-top: 20px;

    &__header {
      background: $black;
      padding: calc(20px) calc(20px) calc(20px) calc(30px);

      .button__close {
        color: $white;

        &:hover {
          color: $delete;
        }

        i {
          top: calc(2px);
          left: calc(10px);
        }
      }
    }

    &-inner {
      padding: calc(50px);

      ul {
        li {
          color: $white !important;
        }
      }
    }
  }
}

.page-sidebar {
  transition: all 150ms ease-in-out;
}

.has-width {
  margin: 0;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>
